module directives {
    export module applicationcore {

        interface IMenuLinkItemScope extends ng.IScope {
            item: interfaces.applicationcore.IMenu,
            clicked(): void,
            menuToggle(): void
        }

        export class menuLinkItemDirective implements ng.IDirective {
            scope = {
                item: "=",
                menuToggle: "&"
            }

            templateUrl = 'templates/modules/applicationcore/menuLinkItemView.html';
            restrict = 'E';
            transclude = true;

            constructor(private $timeout: ng.ITimeoutService, private $state: ng.ui.IStateService) {
            }

            link = ($scope: IMenuLinkItemScope, $element: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
                $scope.clicked = () => {
                    $scope.$root.$broadcast("MenuOpen", '');
                    $scope.menuToggle();
                };
            };

            static factory(): ng.IDirectiveFactory {
                const directive = ($timeout, $state) => new menuLinkItemDirective($timeout, $state);
                directive.$inject = ['$timeout', '$state'];

                return directive;
            }
        }

    }

    angular.module("app").directive("menuLink", directives.applicationcore.menuLinkItemDirective.factory());
}